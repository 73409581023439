<template>

	<div class="cont" id="p08">
		<div class="page-title">{{ program.name }}</div>

		<div class="c01">
			<div class="s01">
				<div class="s-item">
					<div class="s-header">
						<div class="s-title">기본정보</div>
						
					</div>
					<div class="s-body">
						<div class="s-content">
							<div class="si-title">영업점</div>
							<div class="si-desc text-right">{{ user.basic.userId }}</div>
						</div>
						<div class="s-content">
							<div class="si-title">비밀번호</div>
							<div class="si-desc">
								<a @click="viewPassword" class="float-right">
									<div class="s-link" style="color: green;">비밀번호 변경<i class="xi-external-link"></i></div>
								</a>
							</div>
						</div>
						<div class="s-content">
							<div class="si-title">계정 상태</div>
							<div class="si-desc text-right">
								<span :class="item.basic.status">정상 사용</span>
							</div>
						</div>
					</div>
				
				</div>
			</div>

			<div class="s03">
				<a @click="$router.go(-1)"><div class="btn-save">확인</div></a>
			</div>
		</div>
		<MerchantDetailPopupPassword 
			v-if="showPopup.password"
			:item="item"
			
			@setOverlay="setOverlay"
			@setNotify="setNotify"
		/>
		
		</div>
</template>

<script>

	import { rules } from '@/resources/rules/rules'
	import { filter } from '@/resources/filter/filter'
	
	import MerchantDetailPopupPassword from '@/view/Merchant/MerchantDetailPopupPassword'
	
	export default{
		name: "AgentItem"
		,props: ['user']
		,components: { MerchantDetailPopupPassword }
		,data: function(){
			return {
				program: {
					name: this.$language.menu[4].list[0].name
				}
				,item: {
					basic: {}
					,manger: {}
					,company: {}
					,settlement: {}
					,paymentItems: {}
				}
				,showPopup: {
					password: false
					,account: false
				}
				,rules:rules
			}
		}
		,filter: filter
		,computed: {
		}
		,methods: {
			viewPassword: function(){
				this.showPopup.password = true
				this.$emit('setOverlay', true)
			}
			,setOverlay: function(type){
				this.$emit('setOverlay', type)
				if(!type){
					this.showPopup.password = false
					this.showPopup.account = false
				}
			}
			,setNotify: function(option){
				this.$emit('setNotify', option)
			}
		}
		,created: function(){
		}
		,watch: {
		}
	}
</script>

<style>
	.table.type11 .item-header .item .td {width: 50% !important; justify-content: center !important }
	.table.type11 .item-list .item .td {width: 50% !important; justify-content: center !important }
</style>





















